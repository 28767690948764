// imports
import React from "react"
import { graphql } from "gatsby"
import { withPrefix } from "gatsby"
import useScript from "../hooks/useScript"
import SEO from "../components/SEO"
import Navbar from "../components/Navbar"
import ResearchSection from "../components/ResearchSection"


export default function Research({ data }) {
    useScript(withPrefix("scripts/jquery.min.js"));
    useScript(withPrefix("scripts/jquery.waypoints.min.js"));
    useScript(withPrefix("scripts/waypoint.js"));
    useScript("https://www.googletagmanager.com/gtag/js?id=UA-159994104-1");
    useScript(withPrefix("scripts/google-analytics.js"));

    return (
        <div>

        <SEO title="Research" description={data.site.siteMetadata.description} />
        <div id="colorlib-page">
            <Navbar />
            <div id="colorlib-main">
                <ResearchSection data={data}/>
            </div>
        </div>
        
        </div>
    )
}


export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
        research: allMarkdownRemark(
            filter: {
                frontmatter: {
                    contentType: {
                        in: ["research"]
                    }
                }
            }
            sort: {
                fields: [frontmatter___displayOrder]
                order: ASC
            }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        displayOrder
                        featuredImage {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                        topic
                        blurb
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
